export default function ContactMe() {
    const openTelegram = () => {
        window.open('https://t.me/AySaduke', '_blank'); // Opens in a new tab
    };
    const openVK = () => {
        window.open('https://vk.com/club227106451', '_blank'); // Opens in a new tab
    };



    return (
        <section id="Contact" className="contact--section">
            <div>
                <p className="sub--title">Контакты</p>
                <h2>Связаться с нами</h2>
                <p className="text-lg">
                    Вы можете написать напрямую нам в соц сетях
                </p>
            </div>
            <div>
                <button className="btn btn-primary contact--form--btn" onClick={openTelegram}>Телеграм</button>
            </div>
            <div>
                <button className="btn btn-primary contact--form--btn" onClick={openVK}>ВКонтакте</button>
            </div>
            <div>
                <p className="text-lg">
                    или оставить письмо на почту
                </p>
                <h2>mestoria@inbox.ru</h2>
            </div>
        </section>
    );
}