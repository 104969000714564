import logo from '../assets/company_page.png';

export default function CompanySection() {
    const openSite = () => {
        window.open('https://t.me/AySaduke', '_blank'); // Opens in a new tab
    };

    return (
        <section id="CompanySection" className="company--section">
            <div className="company--section--content--box">
                <div className="company--section-conent">
                    <p className="company--title">Первая</p>
                    <h1 className="company--section--title">
                        <span className="company--section--title--color">Туристическая</span>{" "} <br />
                        Медиаплатформа
                    </h1>
                    <p className="company--section--description">с иммерсивным
                        <br />
                        контентом
                    </p>
                </div>
                <button onClick={openSite} className="btn btn-primary">Заказать демо</button>
            </div>
            <div className="company--section--img">
                <img src={logo} alt="" /></div>
        </section>
    )
}