export default function AboutUs() {
    return (
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <img src="./img/about_us.png" alt="About Me" />
            </div>
            <div className="company--section--content--box about--section--box">
                <div className="company--section--content">
                    <p className="section--title">О проекте</p>
                    <h1 className="skills-section--heading">Местория</h1>
                    <p className="company--section-description">
                        Это сервис, в котором можно создавать истории в дополненной реальности и формировать туристические маршруты по ним. 
                        Вы можете презентовать свой маршрут по QR коду или в мобильном приложении.
                    </p>
                    <p className="company--section-description">
                        Местория - место вашей истории!
                    </p>
                </div>
            </div>
        </section>
    );
}